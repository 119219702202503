/**
 * Styles via ITCSS Methodology
 *
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported to ensure the consistent increase of specificity.
 *
 *  1. Settings...........Global configuration and variables
 *  2. Tools..............Functions and mixins
 *  3. Generic............Ground zero styles (normalizing rules, box-sizing etc...)
 *  4. Elements...........Unclassed (bare) HTML elements (like H1, Ul, A etc...)
 *  5. Objects............Common non-cosmetic structural design patterns
 *  6. Components.........Specific cosmetic elements of UI
 *  7. Utilities..........Helpers and overrides
 *
 *     Modules............Multi-part components e.g. Navbar (HTML, CSS and JS).
 *     Shame..............All the CSS declarations, hacks and things we are not proud of.
 */

@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'), url(https://fonts.gstatic.com/s/alegreyasans/v9/5aUz9_-1phKLFgshYDvh6Vwt7VptvQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Alegreya Sans Medium'), local('AlegreyaSans-Medium'), url(https://fonts.gstatic.com/s/alegreyasans/v9/5aUu9_-1phKLFgshYDvh6Vwt5alOqEp2iw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Alegreya Sans Light'), local('AlegreyaSans-Light'), url(https://fonts.gstatic.com/s/alegreyasans/v9/5aUu9_-1phKLFgshYDvh6Vwt5fFPqEp2iw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Alegreya Sans Thin'), local('AlegreyaSans-Thin'), url(https://fonts.gstatic.com/s/alegreyasans/v9/5aUt9_-1phKLFgshYDvh6Vwt5Tldv21T.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$sl-caption-color: #fff !default;
$sl-caption-background: rgba(138, 180, 186, .8) !default;
$grid-row-columns: 12;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

@import '../../node_modules/slick-slider/slick/slick.scss';
// @import '../../node_modules/slick-slider/slick/slick-theme.scss';

@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "mixins/_alert.scss";
@import "mixins/_background-variant.scss";
@import "mixins/_badge.scss";
@import "mixins/_border-radius.scss";
@import "mixins/_box-shadow.scss";
@import "mixins/_breakpoints.scss";
@import "mixins/_buttons.scss";
@import "mixins/_caret.scss";
@import "mixins/_clearfix.scss";
@import "mixins/_float.scss";
@import "mixins/_forms.scss";
@import "mixins/_gradients.scss";
@import "mixins/_grid-framework.scss";
@import "mixins/_grid.scss";
@import "mixins/_hover.scss";
@import "mixins/_image.scss";
@import "mixins/_list-group.scss";
@import "mixins/_lists.scss";
@import "mixins/_nav-divider.scss";
@import "mixins/_pagination.scss";
@import "mixins/_reset-text.scss";
@import "mixins/_resize.scss";
@import "mixins/_screen-reader.scss";
@import "mixins/_size.scss";
@import "mixins/_table-row.scss";
@import "mixins/_text-emphasis.scss";
@import "mixins/_text-hide.scss";
@import "mixins/_text-truncate.scss";
@import "mixins/_transition.scss";
@import "mixins/_visibility.scss";
@import "tools/_mixins.scss";
@import "generic/_bootstrap.grid.scss";
@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "elements/_headings.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_media.scss";
@import "elements/_page.scss";
@import "elements/_paragraphs.scss";
@import "elements/_tables.scss";
@import "objects/_list-inline.scss";
@import "objects/_list-unstyled.scss";
@import "objects/_section.scss";
@import "components/_buttons.scss";
@import "components/_footer.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_text.scss";
@import "utilities/_visibility.scss";
@import "C:/Users/dvn-a/PhpstormProjects/duester/src/modules/carousel/carousel.scss";
@import "C:/Users/dvn-a/PhpstormProjects/duester/src/modules/navbar/navbar.scss";
@import '../../node_modules/simplelightbox/dist/simplelightbox.scss';
// @import '~@icon/linea-basic-elaboration/linea-basic-elaboration.css';
@import 'shame';

html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
  &.noscroll {
    overflow: hidden;
  }
}
#content {
  position: relative;
  min-height: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
h2 {
  color: #1e381e;
}
.zahnarzt_koeln {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-m;
  div {
    text-align: center;
    h2 {
      font-family: $font-family-m;
      transition: $transition-base;
      white-space: nowrap;
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 0;
      color: $black;
      font-weight: 300;
      .scrolled & {
        font-size: 16px;
        line-height: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.1vw;
        line-height: 1.2vw;
        .scrolled & {
          font-size: 1.1vw;
          line-height: 1.2vw;
        }
      }
    }
    h3 {
      font-family: $font-family-m;
      transition: $transition-base;
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 0;
      color: $primary;
      font-weight: 500;
      .scrolled & {
        font-size: 16px;
        line-height: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.2vw;
        line-height: 1.3vw;
        .scrolled & {
          font-size: 1.1vw;
          line-height: 1.2vw;
        }
      }
    }
    h4 {
      font-family: $font-family-m;
      transition: $transition-base;
      white-space: nowrap;
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 0;
      color: #1e381e;
      font-weight: 300;
      .scrolled & {
        font-size: 16px;
        line-height: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.15vw;
        line-height: 1.25vw;
        .scrolled & {
          font-size: 1.3vw;
          line-height: 1.4vw;
        }
      }
    }
    h5 {
      font-family: $font-family-m;
      transition: $transition-base;
      white-space: nowrap;
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 0;
      color: $primary;
      font-weight: 400;
      .scrolled & {
        font-size: 16px;
        line-height: 16px;
      }

      @include media-breakpoint-up(md) {
        font-size: 1.1vw;
        line-height: 1.2vw;
        .scrolled & {
          font-size: 1.1vw;
          line-height: 1.2vw;
        }
      }
    }
  }
}
.scrolled .zahnarzt_koeln {
  h2 {
    display: none;
  }
  h4 {
    display: none;
  }
  h3 {
    margin-top: 5px;
  }
  h5 {
    margin-top: 5px;
  }
}
.footer .logo a {
  &:hover {
    text-decoration: none;
  }
}
.zahnarzt_koeln_footer {
  align-items: center;
  flex-direction: column;
  display: flex;
  h2 {
    @include media-breakpoint-down(sm) {
      flex: 1;
    }
    color: $white;
    font-size: 38px;
    line-height: 40px;
    @include media-breakpoint-up(md) {
      font-size: 1.6vw;
      line-height: 1.7vw;
    }
    margin-bottom: 5px;
    font-weight: 300;
  }
  h3 {

    @include media-breakpoint-down(sm) {
      flex: 1;
    }
    color: #ecf3ec;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 32px;
    @include media-breakpoint-up(sm) {
      font-size: 34px;
      line-height: 37px;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.7vw;
      line-height: 1.8vw;
    }
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-weight: 700;
  }
  h4 {
    @include media-breakpoint-down(sm) {
      flex: 1;
    }
    color: #ecf3ec;
    font-size: 22px;
    line-height: 24px;
    @include media-breakpoint-up(md) {
      font-size: 1.4vw;
      line-height: 1.5vw;
    }
    margin-bottom: 5px;
    font-weight: 400;
  }
  h5 {
    @include media-breakpoint-down(sm) {
      flex: 1;
    }
    color: #ecf3ec;
    font-size: 23px;
    line-height: 26px;
    @include media-breakpoint-up(md) {
      font-size: 1.4vw;
      line-height: 1.5vw;
    }
    margin-bottom: 5px;
    font-weight: 300;
  }
  div {
    text-align: center;
    @include media-breakpoint-down(sm) {
      flex: 1;
      padding-left: 20px;
    }
    img {
      margin: 0 auto 10px auto;
      @include media-breakpoint-up(md) {
        max-width: 60%;
      }
    }
  }
}
h1 {
  color: #1e381e;
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
}
.bcg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.tinner {
  padding-top: 10vh;
  padding-bottom: 13vh;
}
.textblock {
  // padding: 10px 10px 7px 10px;
  ul {
    padding: 0 0 0 16px;
    li {
      font-size: 1.05rem;
      color: #404040;
      line-height: 1.4;
      margin-bottom: 3px;
    }
  }
  position: relative;
  &.has-padding-lr {
    padding-left: 15px;
    padding-right: 15px;
  }
  h2 {
    color: $primary;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 46px;
    }
    letter-spacing: 1px;
  }
  p {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 300;
    a:not(.btn) {
      text-decoration: underline;
    }
  }
  &.teaser {
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
.ovi {
  display: none;
}
.bgcolor-news {
  background-color: #197433;
  .ncc {
    display: flex;
    align-items: center;
    i {
      font-weight: 700;
      font-size: 50px;
      color: white;
      padding-left: 10px;
      padding-right: 24px;
    }
  }
  .texthld {
    padding-top: 4px;
    p {
      margin-bottom: .3rem;
      letter-spacing: 1px;
      font-size: 19px;
    }
  }
  .date {
    color: $white;
    border-bottom: 1px solid $white;
  }
  h5 {
    margin-bottom: 4px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul {
    color: $white;
  }
}
.bgcolor-1 {
  background-color: #48a0ba;
  .textblock {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $white;
    }
  }
}
.bgcolor-2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .ovi {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(48, 112, 129, .45);
  }
  .textblock {
    h4,
    h5 {
      color: $white;
      letter-spacing: 1px;
    }
    h1 {
      color: $white;
      text-shadow: 2px 2px 2px rgba(57, 57, 57, .8);
      letter-spacing: 1px;
    }
    h2 {
      color: $white;
      text-shadow: 2px 2px 2px rgba(57, 57, 57, .8);
      letter-spacing: 1px;
    }
    p {
      color: $white;
      letter-spacing: 1px;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
    ul {
      color: $white;
    }
  }
}
body.skrollrm {
  .bgcolor-2 {
    .ovi {
      opacity: .55;
    }
  }
}
.bgcolor-3 {

}
@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
  }
}


.slick-slide {
  height: 108vh;
  > div {
    height: 100%;
    .holder {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding-bottom: 20px;
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
        align-items: center;
      }
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      //justify-content: center;
      .tho {
        // background-color: rgba(50, 94, 50, .8);
        // izad
        background-color: rgba(50, 94, 50, .9);
        padding: 3px 12px;
        margin-bottom: 40px;
        @include transition(all .2s ease-in-out);
        @include media-breakpoint-up(md) {
          max-width: 56%;
          display: inline-block;
          margin-bottom: 0;
        }
        &:hover {
          background-color: rgba(50, 94, 50, .75);
        }
      }
      h2 {
        color: #fff;
        font-weight: 300;
        font-size: 35px;
        line-height: 37px;
        @include media-breakpoint-up(md) {
          font-size: 48px;
          line-height: 52px;
        }
        // letter-spacing: -1px;
        // text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
      }
      h3 {
        color: #fff;
        font-weight: 300;
        font-size: 24px;
        line-height: 26px;
        @include media-breakpoint-up(md) {
          font-size: 29px;
          line-height: 32px;
        }
        // letter-spacing: -1px;
        // text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
      }
      .btn {
        font-size: 1.18em;
        padding: .2rem .75rem;
        margin-left: 5px;
        background-color: #1e381e;
      }
    }
  }
  .slide {
    height: 100%;
  }
  z-index: 2;
}
.slick-arrow {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  background-color: $white;
  opacity: .75;
  z-index: 3;
  background-size: 44%;
  background-position: center center;
  background-repeat: no-repeat;
  top: 30px;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    top: 38vh;
  }
  @include transition(all .2s ease-in-out);
  &:hover {
    opacity: 1;
  }
  &.slick-prev {
    left: 20px;
    background-image: url(../assets/img/chevronleft-blue.svg);
  }
  &.slick-next {
    right: 20px;
    background-image: url(../assets/img/chevronright-blue.svg);
  }
}
.padding-top-1 {
  padding-top: 15px;
}
.padding-bottom-1 {
  padding-bottom: 20px;
}
.articlelist {
  .listing {
    margin-bottom: 30px;
    position: relative;
    @include media-breakpoint-up(md) {
      background-size: cover;
      min-height: 220px;
    }
    .inner {
      background-color: #7d9b7d;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
      }
      position: relative;
      height: 100%;
      .ovi {
        display: block;
        height: 100%;
        width: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(253, 253, 253, .8);
        pointer-events: none;
        @include transition(all .35s ease-in-out);
        z-index: 1;
      }
      h3 {
        position: relative;
        font-size: 23px;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $black;
        z-index: 2;
        letter-spacing: 1px;
        text-shadow: 2px 2px 2px rgba(150, 150, 150, .3);
      }
      p {
        position: relative;
        color: $black;
        font-size: 21px;
        z-index: 2;
        letter-spacing: 1px;
        text-shadow: 2px 2px 2px rgba(150, 150, 150, .3);
      }
      .holder {
        @include transition(all .35s ease-in-out);
        opacity: 1;
      }
    }
    &:hover {
      text-decoration: none;
      z-index: 2;
      .ovi {
        background: rgba(253, 253, 253, .9);
      }
      h3 {
        // text-shadow: 2px 2px 2px rgb(249, 249, 249);
      }
      p {
        // text-shadow: 2px 2px 2px rgb(249, 249, 249);
      }
      .holder {

      }
    }
  }
  a {
    margin-bottom: 30px;
    position: relative;
    @include media-breakpoint-up(md) {
      background-size: cover;
      min-height: 220px;
    }
    @include transition(all .15s linear);
    z-index: 1;
    img {
      position: absolute;
      right: 25px;
      bottom: 15px;
      width: 22px;
      z-index: 2;
      background: #1e381e;
      padding-left: 7px;
      padding-right: 6px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .inner {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
      }
      position: relative;
      height: 100%;
      @include transition(all .35s ease-in-out);
      .ovi {
        display: block;
        height: 100%;
        width: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(253, 253, 253, .7);
        pointer-events: none;
        @include transition(all .35s ease-in-out);
        z-index: 1;
      }
      h3 {
        position: relative;
        font-size: 23px;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $black;
        z-index: 2;
        letter-spacing: 1px;
        text-shadow: 2px 2px 2px rgba(150, 150, 150, .3);
      }
      p {
        position: relative;
        color: $black;
        font-size: 21px;
        z-index: 2;
        letter-spacing: 1px;
        text-shadow: 2px 2px 2px rgba(150, 150, 150, .3);
      }
      .holder {
        @include transition(all .35s ease-in-out);
        opacity: 1;
      }
    }
    &:hover {
      text-decoration: none;
      transform: scale(1.033);
      z-index: 2;
      .ovi {
        background: rgba(253, 253, 253, .7);
      }
      h3 {
        // text-shadow: 2px 2px 2px rgb(249, 249, 249);
      }
      p {
        // text-shadow: 2px 2px 2px rgb(249, 249, 249);
      }
      .holder {

      }
    }
  }
}
.module-box {
  img {
    margin-bottom: 15px;
  }
  h3 {
    color: #1e381e;
    font-weight: 400;
    font-size: $font-size-base * 1.3;
  }
  h4 {
    color: $black;
    font-weight: 400;
    font-size: $font-size-base * 1.2;
  }
  p {
    &.zitat {
      font-weight: 400;
      font-style: italic;
    }
  }
}
#back-top {
  bottom: 6px;
  border-radius: 4px;
  position: fixed;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #197432;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.cc-window {
  left: 30px;
  bottom: 30px;
  width: calc(100% - 30px);
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  padding: 10px;
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  z-index: 999999;
  border: 1px solid #fff;
}
.cc-btn {
  padding: .5rem .75rem;
  border: 1px solid transparent;
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: inherit;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  border-radius: 0;
  &:hover {
    text-decoration: none;
  }
}
.cc-link {
  text-decoration: underline;
}
#map_canvas {
  width: 100%;
  height: 350px;
}
.bg-colegues {
  background: #f6fbfb;
  padding-top: 30px;
  padding-bottom: 25px;
}
.trenner {
  width: 100%;
  &.trennerlinie {
    width: 100%;
    position: relative;
    span {
      position: absolute;
      display: block;
      height: 1px;
      width: 100%;
      background: #197432;
    }
  }
}
.bg_route_form {
  background: #197432;
}
.routenplaner {
  display: flex;
  padding: 5px;
  input[type="text"] {
    flex-grow: 1;
  }
}

table,
th,
td{
  border-color: #d7d7d7;
  padding: 6px;
  vertical-align: top;
}

th {
  color: #fff;
  background-color: #373737;
  padding: 6px;
}

tr {
  color: inherit;
  background-color: #fbfbfb;
}

tr:nth-child(even) {
  color: inherit;
  background-color: #f7f7f7;
}

table a {
  background-color: inherit;
}
#mobile-adress {
  position: absolute;
  bottom: 3px;
  width: 90%;
  left: 5%;
  display: none;
  h4 {
    text-align: center;
    font-size: 18px;
  }
  h5 {
    text-align: center;
    font-size: 16px;
  }
}
.navbar--opened {
  #mobile-adress {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .mobile-mt-1 {
    margin-top: 15px;
  }
}
@include media-breakpoint-down(sm) {
  .mobile-mt-2 {
    margin-top: 30px;
  }
}
.openings_contact {
  @extend .h5;
}
.oeff {
  font-size: 20px;
}
.personal {
  img {
    margin-bottom: 15px;
  }
  ul {
    padding: 0 0 0 16px;
    li {
      margin-bottom: 3px;
      line-height: 1.4;
    }
  }
}
#nav {
  li {
    &:first-child {
      display: none;
    }
  }
}
body.pehind_open {
  overflow: hidden;
  .pehind {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}
.pehind {
  position: fixed;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  top: 0;
  background: rgba(170, 219, 233, .79);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .9s ease-in-out;
  opacity: 0;
  .popup {
    border: 1px solid $gray-400;
    background: $white;
    width: 92%;
    min-height: 130px;
    height: 85%;
    overflow: hidden;
    position: relative;
    font-size: 1.05rem;
    @include media-breakpoint-up(md) {
      width: 82%;
    }
    .scroller {
      width: 100%;
      max-height: 100%;
      overflow: auto;
      .inner {
        padding: 10px;
      }
    }
    #close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 25px;
      top: 5px;
      svg {
        width: 35px;
        height: 35px;
      }
    }
    h3 {
      color: #325e32;
      padding-right: 33px;
    }
    ul {
      padding: 0 0 0 15px;
      margin: 0;
      li {
        font-size: 1.05rem;
        color: #404040;
        line-height: 1.4;
        margin-bottom: 3px;
      }
    }
  }
}
