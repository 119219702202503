/**
 * Global
 *
 * !default Flag
 * When building a library, a framework, a grid system or any piece of Sass
 * that is intended to be distributed and used by external developers,
 * all configuration variables should be defined with the !default flag
 * so they can be overwritten.
 */

// Fonts

$font-family: 'Alegreya Sans', "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-m: 'Montserrat', "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-size-base: 1.05rem !default;
$line-height-base: 1.6 !default;
$body-color: #404040 !default;

$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query:   true !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$theme-colors: () !default;

$text-muted: $gray-600 !default;

// Transition
$transition-base: all .2s ease-in-out !default;
