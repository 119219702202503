/**
 * Headings
 */

$headings-margin-bottom: .6rem !default;
$headings-font-family: $font-family !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$h1-font-size: $font-size-base * 2.4 !default;
$h2-font-size: $font-size-base * 1.9 !default;
$h3-font-size: $font-size-base * 1.55 !default;
$h4-font-size: $font-size-base * 1.4 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-sm: .875 !default;
$headings-md: 1 !default;
$headings-lg: 1.125 !default;
$headings-xl: 1.25 !default;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h3-font-size * $headings-sm;
}
h2,
.h2 {
  font-size: $h2-font-size * $headings-sm;
}
h3,
.h3 {
  font-size: $h3-font-size * $headings-sm;
}
h4,
.h4 {
  font-size: $h4-font-size * $headings-sm;
}
h5,
.h5 {
  font-size: $h5-font-size * $headings-sm;
}
h6,
.h6 {
  font-size: $h6-font-size * $headings-sm;
}

@include media-breakpoint-up(md) {
  h1,
  .h1 {
    font-size: $h3-font-size * $headings-md;
  }
  h2,
  .h3 {
    font-size: $h2-font-size * $headings-md;
  }
  h3,
  .h3 {
    font-size: $h3-font-size * $headings-md;
  }
  h4,
  .h4 {
    font-size: $h4-font-size * $headings-md;
  }
  h5,
  .h5 {
    font-size: $h5-font-size * $headings-md;
  }
  h6,
  .h6 {
    font-size: $h6-font-size * $headings-md;
  }
}

@include media-breakpoint-up(lg) {
  h1,
  .h1 {
    font-size: $h3-font-size * $headings-lg;
  }
  h2,
  .h3 {
    font-size: $h2-font-size * $headings-lg;
  }
  h3,
  .h3 {
    font-size: $h3-font-size * $headings-lg;
  }
  h4,
  .h4 {
    font-size: $h4-font-size * $headings-lg;
  }
  h5,
  .h5 {
    font-size: $h5-font-size * $headings-lg;
  }
  h6,
  .h6 {
    font-size: $h6-font-size * $headings-lg;
  }
}

@include media-breakpoint-up(xl) {
  h1,
  .h1 {
    font-size: $h3-font-size * $headings-xl;
  }
  h2,
  .h3 {
    font-size: $h2-font-size * $headings-xl;
  }
  h3,
  .h3 {
    font-size: $h3-font-size * $headings-xl;
  }
  h4,
  .h4 {
    font-size: $h4-font-size * $headings-xl;
  }
  h5,
  .h5 {
    font-size: $h5-font-size * $headings-xl;
  }
  h6,
  .h6 {
    font-size: $h6-font-size * $headings-lg;
  }
}
