/**
 * Footer
 */

.footer {
  background-color: #808080;
  padding: 2.5rem 0;
  color: #fff;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 500;
    color: #f6fbfb;
    font-size: 19px;
    letter-spacing: 2px;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
  p {
    font-size: 20px;
    a {
      color: #fff;
      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        img {
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }
  .logo {
    @include media-breakpoint-up(md) {
      padding-right: 10px;
    }
  }
}
